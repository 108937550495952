import React, { useContext } from 'react';
import { AppContext } from '../contexts/app-context';
import { Box, Container, Flex, Heading } from 'theme-ui';

import thumbImg from 'assets/images/prompt-enhancer-demo-thumb.png';

const Chat = () => {
  const { language } = useContext(AppContext);
  const es = language == "es";

  return (
    <Box as="section" id="chat" sx={styles.chat}>
      <Container>
        <Flex sx={styles.flex}>
          <Box sx={styles.content}>
            <span>
              Chat
            </span>
            <Heading as="h3">
              {es ?
              "Tu propio redactor personal de Inteligencia Artificial"
              :
              "Your own personal AI copywriter"
              }
            </Heading>
            <p>
              {es ?
              <>Simplemente describe lo que necesitas y Writelytic generará al instante copy de marketing de alta conversión adaptado a tus objetivos.<br/>Refina fácilmente tus prompts con nuestro inteligente potenciador de prompts para obtener resultados aún mejores.</>
              :
              <>Just describe what you need and Writelytic will instantly generate high-converting marketing copy tailored to your goals.<br/>Easily refine your prompts with our intelligent prompt enhancer to get even better results.</>
              }
            </p>
          </Box>
          <Box sx={styles.demo}>
            <Heading as="h4">
              {es ?
              "Demo del potenciador de prompts"
              :
              "Prompt enhancer demo"
              }
            </Heading>
            <Box sx={styles.vWrapper}>
              <iframe
                src={`https://pedemoplayer.writelytic.com/?lang=${es ? "es" : "en"}`}
                scrolling="no"
                frameborder="0"
                allowFullScreen/>
            </Box>
            {/*<video src={
              es ?
              "https://writelyticpedemo.s3.us-east-2.amazonaws.com/es/prompt-enhancer-demo.mp4"
              :
              "https://writelyticpedemo.s3.us-east-2.amazonaws.com/en/prompt-enhancer-demo.mp4"
            } controls controlsList="nodownload" poster={thumbImg}
            >
              {es ? "Tu navegador no soporta vídeo HTML." : "Your browser does not support HTML video."}
          </video>*/}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Chat;

const styles = {
  chat: {
    mt: ['-90px', null, null, null, '0'],
    py: ['50px', null, null, null, '110px', null, '140px'],
  },
  flex: {
    flexWrap: 'wrap',
  },
  content: {
    flex: ['0 0 100%', null, null, null, '0 0 50%'],
    textAlign: ['center', null, null, null, 'left'],
    pt: ['80px', null, null, null, '0'],
    maxWidth: ['100%', null, null, '80%', '100%'],
    mx: ['auto', null, null, null, '0'],
    mb: ['30px', null, null, null, '0'],
    "&>span": {
      fontSize: '18px',
      fontWeight: 700,
      color: 'primary',
      display: 'block',
      lineHeight: 1,
    },
    h3: {
      color: '#0F2137',
      fontWeight: 700,
      fontSize: ['23px', null, null, null, '30px', '36px', '44px'],
      maxWidth: ['100%', null, null, null, null, '90%', '100%'],
      lineHeight: 1.36,
      letterSpacing: '-1.5px',
      mt: '20px',
      mb: '30px',
    },
    p: {
      color: '#02073E',
      fontSize: ['16px', null, null, '18px'],
      lineHeight: ['2', null, null, 2.33],
      mb: '30px',
    },
  },
  demo: {
    textAlign: "center",
    flex: ['0 0 100%', null, null, null, '0 0 50%'],
    px: "1rem",
  },
  vWrapper: {
    position: "relative",
    overflow: "hidden",
    paddingBottom: "56.25%", /* 16:9 */
    width: "100%",
    "& iframe": {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: "100%",
      height: "100%",
    },
  }
};
